export const depotFormVO = function () {
  return {
    depotInfo: {
      name: '',
      department: {
        id: null,
        name: ''
      },
      status: '',
      longitude: null,
      latitude: null,
      area: {
        id: null,
        name: ''
      },
      location: '',
      orgId: 0
    },
    imageIds: [],
    images: [],
    fileIds: [],
    files: [],
  }
}