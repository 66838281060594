<template>
  <a-skeleton active :loading="ifSub">
    <div class="details">
      <div class="details-title">
        <div class="details-title-text">基础信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <a-row class="details-content-rowBasicInfo">
        <a-col :span="12"><span>仓库名称：</span>{{ formItem.depotInfo.name || '-' }}</a-col>
        <a-col :span="12"><span>所属部门：</span> {{ formItem.depotInfo.department.name || '-' }}</a-col>
        <a-col :span="12"
          ><span>仓库状态：</span>
          <span v-if="formItem.depotInfo.status === 'START'">启用</span>
          <span v-else-if="formItem.depotInfo.status === 'STOP'">停用</span>
          <span v-else>-</span>
        </a-col>
      </a-row>
      <div class="details-title">
        <div class="details-title-text">位置信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <a-row class="details-content-rowBasicInfo">
        <a-col :span="24"><span>具体地址：</span> {{ formItem.depotInfo.detailLocation || '-' }}</a-col>
        <a-col :span="12"><span>经度：</span> {{ formItem.depotInfo.longitude || '-' }}</a-col>
        <a-col :span="12"><span>纬度：</span>{{ formItem.depotInfo.latitude || '-' }}</a-col>
      </a-row>
      <div class="details-title">
        <div class="details-title-text">图片信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <div class="details-content-rests">
        <template v-if="formItem.images.length">
          <qiniu-image-upload ref="qiniuImageRef" type="view" :items="formItem.images" />
        </template>
        <template v-else>
          <div class="details-content-rests-noImageState">
            <a-icon class="details-content-rests-noImageState-icon" type="smile" />
            <span>暂未上传图片</span>
          </div>
        </template>
      </div>
      <div class="details-title">
        <div class="details-title-text">文件信息</div>
        <i class="details-title-text-i"></i>
      </div>
      <div class="details-content-rests">
        <template v-if="formItem.files.length">
          <qiniu-file-upload ref="qiniuFileRef" type="view" :items="formItem.files"></qiniu-file-upload>
        </template>
        <template v-else>
          <div class="details-content-rests-noImageState">
            <a-icon class="details-content-rests-noImageState-icon" type="smile" />
            <span>暂未上传文件</span>
          </div>
        </template>
      </div>
    </div>
  </a-skeleton>
</template>

<script>
import SERVICE_URLS from '@/api/service.url'
import { depotFormVO } from './common/depotFormVO'
import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'
import QiniuFileUpload from '@/components/QiniuUpload/QiniuFileUpload'
export default {
  name: 'DepotDetail',
  components: { QiniuImageUpload, QiniuFileUpload },
  data() {
    return {
      ifSub: false,
      formItem: depotFormVO(),
      labelCol: { span: 4 },
      wrapperCol: { span: 19 },
      tableData: [],
    }
  },
  methods: {
    loadData(id) {
      this.$http(this, {
        url: SERVICE_URLS.equipment2.depotApi.view,
        params: { id },
        noTips: true,
        success: (data) => {
          Object.assign(this.formItem, data.body)
        },
      })
    },
  },
}
</script>

<style scoped>
.text-title {
  font-size: 17px;
  font-weight: 700;
  font-family: 'Lantinghei SC', 'Open Sans', Arial, 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', 'STHeiti',
    'WenQuanYi Micro Hei', SimSun, sans-serif;
}
</style>
