<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">仓库管理</h1>
    </div>
    <div slot="extra">
      <a-button
        style="margin-left: 16px;"
        icon="plus"
        @click="handleAdd"
        type="primary"
        v-auth="auth_equipment2.DEPOT_CREATE_AND_UPDATE"
        >新增</a-button
      >
    </div>
    <a-card :bordered="false">
      <div class="fs-search-box">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="仓库名称">
              <a-input v-model="searchParams.name" placeholder="请输入仓库名称" allowClear />
            </a-form-item>
            <a-form-item label="状态">
              <a-select
                style="width: 182px;"
                placeholder="请输入选择状态"
                allowClear
                v-model="searchParams.status"
                showSearch
              >
                <a-select-option v-for="item in checkType" :key="item.value" :value="item.value" :label="item.label">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="所属部门">
              <a-tree-select
                style="width: 210px;"
                :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                :treeData="orgTree"
                allowClear
                placeholder="请选择部门"
                v-model="searchParams.departmentId"
              ></a-tree-select>
            </a-form-item>
            <a-form-item label="所在位置">
              <a-input v-model="searchParams.location" placeholder="请填写位置" allowClear />
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :customRow="handleView"
        :rowKey="(record) => record.id"
        bordered
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="name" slot-scope="text, record">
          {{ record.name }}
          <!--<span>{{record.name}}</span>-->
        </template>
        <template slot="status" slot-scope="text, record">
          <span v-if="record.status === 'START'">启用</span>
          <span v-if="record.status === 'STOP'">停用</span>
        </template>
<!--        <template slot="location" slot-scope="text, record">{{ record.location }} </template>-->
        <template slot="action" slot-scope="text, record">
          <a @click="handleEdit(record.id)" v-auth="auth_equipment2.DEPOT_CREATE_AND_UPDATE" @click.stop>编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record.id)" v-auth="auth_equipment2.DEPOT_DELETE" @click.stop>删除</a>
        </template>
      </a-table>
    </a-card>
    <!--添加Modal-->
    <a-modal title="新增仓库" v-model="addModal" width="560px">
      <depot-form ref="addRef" @addSuccess="addSuccess" @addError="addError"></depot-form>
      <template slot="footer">
        <a-button :loading="addLoading" @click="handleAddOkWithContinue" type="primary" style="margin-right: 5px;"
          >保存新增
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOkWithCopy" type="primary" style="margin-right: 5px;"
          >保存复制
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOk" type="primary" style="margin-right: 5px;"
          >保存关闭</a-button
        >
        <a-button @click="closeAddModal">取消</a-button>
      </template>
    </a-modal>
    <!--修改Modal-->
    <a-modal
      title="修改仓库"
      :visible="editModal"
      @ok="handleEditOk"
      @cancel="closeEditModal"
      :confirmLoading="editLoading"
    >
      <depot-form ref="editRef" @editSuccess="editSuccess" @editError="editError"> </depot-form>
    </a-modal>
    <a-drawer
      title="仓库详情"
      placement="right"
      :closable="true"
      @close="detailClose"
      :width="600"
      :visible="detailDrawer"
      :mask="false"
    >
      <depot-detail ref="viewRef"></depot-detail>
    </a-drawer>
  </page-layout>
</template>

<script>
import entityCRUDWithCopy from '../../common/mixins/entityCRUDWithCopy'
import PageLayout from '@/components/page/PageLayout'
import SERVICE_URLS from '@/api/service.url'
import { depotColumns, statusList } from './common/common'
import DepotForm from './Form'
import DepotDetail from './Detail'

export default {
  name: 'YList',
  mixins: [entityCRUDWithCopy],
  components: { PageLayout, DepotForm, DepotDetail },
  data() {
    return {
      entityBaseUrl: SERVICE_URLS.equipment2.depotApi,
      searchParams: {
        number: '0',
        size: '10',
        direction: '',
        order: '',
        name: '',
        status: undefined,
        departmentId: undefined,
        location: '',
      },
      initColumns: depotColumns(),
      checkType: statusList(),
      orgTree: [],
    }
  },
  created() {
    this.getOrgTree()
  },
  methods: {
    getOrgTree() {
      this.$http(this, {
        url: SERVICE_URLS.organization.antTree,
        noTips: true,
        success: (data) => {
          this.orgTree = data.body
        },
      })
    },
    detailClose() {
      this.detailDrawer = false
    },
    handleView(record) {
      return {
        on: {
          click: () => {
            this.detailDrawer = true
            this.$nextTick(() => {
              this.$refs.viewRef.loadData(record.id)
            })
          },
        },
      }
    },
  },
}
</script>

<style scoped></style>
